import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layouts/Layout"

const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <main className="">
          <article className="prose mx-auto">
            <h1>404.</h1>
            <p>Sorry, you hit a route that doesn't exist.</p>
            <p>
              <Link to="/">Go to homepage</Link>
            </p>
          </article>
        </main>
      </Layout>
    </>
  )
}

export default NotFoundPage
